import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

import Button from 'react-bootstrap/Button'
import { AiOutlineInstagram } from "react-icons/ai";
import { BiPhone } from "react-icons/bi";


const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "underconstruction.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        logo: file(relativePath: {eq: "logo-icon-transparent-colored.png"}) {
          childImageSharp {
            fixed(width: 60) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      // const imageData = data.desktop.childImageSharp.fluid
      const imageData = [
        data.desktop.childImageSharp.fluid,
        `linear-gradient(225deg, rgba(26,49,116,0.9) 0%, rgba(41,76,210,0.3) 100%)`,
      ].reverse()

      return (
        <Layout>
          <SEO title="UrbanBud - будівельна компанія" description="Будівельна компанія UrbanBud. Будівництво комерційної та житлової нерухомості в Україні." />
          <BackgroundImage
            Tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#1A3174`}
          >
            <div className="contain">
              <h1><Img fixed={data.logo.childImageSharp.fixed} className="logo" alt="UrbanBud логотип" />UrbanBud</h1>
              <p className="slogan">Будівництво комерційної та житлової нерухомості</p>
              <div className="contacts">
                <Button variant="warning" href="tel:+380502477700" className="phone"><BiPhone /> (050) 247-7700</Button>
                <Button variant="warning" href="https://www.instagram.com/urbanbud_ua/" target="_blank" rel="noreferrer"><AiOutlineInstagram /> Ми в інстаграм</Button>
              </div>
            </div>
          </BackgroundImage>
        </Layout>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  height: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection
